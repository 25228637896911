import { useEffect, useRef } from "react";

import { selectPeers, selectPeersScreenSharing, useHMSStore, } from "@100mslive/react-sdk";
import Peer from "./Peer";
import { ScreenTile } from "./ScreenTile";

function Conference() {
    const audioRef = useRef(null);
    const peers = useHMSStore(selectPeers);
    const presenters = useHMSStore(selectPeersScreenSharing);
    const hostObj = peers?.find((peer) => peer.roleName === 'host');
    const guestObj = peers?.find((peer) => peer.roleName === 'guest');

    const peerArr = [];

    if (hostObj) {
        peerArr.push(hostObj);
    }
    if (guestObj) {
        peerArr.push(guestObj);
    }

    useEffect(() => {
        if (peerArr.length === 1) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [peerArr]);

    return (
        <div className="conference-section">
            <div className="d-flex align-items-center w-100 h-100 customPeerContainer">
                {peerArr.map((peer) => (
                    <Peer key={peer.id} peer={peer} />
                ))}
                {/* {presenters.map((peer) => (
                    <ScreenTile key={"screen" + peer.id} peer={peer} />
                ))} */}
                <audio
                    // className="d-none"
                    ref={audioRef}
                    autoPlay={true}
                    loop={true}
                    muted={false}
                    // controls={true}
                    src={"/audio/spring-sunshine-piano-solo.mp3"}
                />
            </div>
        </div>
    );
}

export default Conference;
