import { useEffect, useState } from "react";
import {
    AudioLevelIcon,
    ExitIcon,
    MicOffIcon,
    MicOnIcon,
    ShareScreenIcon,
    VideoOffIcon,
    VideoOnIcon,
} from "@100mslive/react-icons";
import {
    selectIsLocalAudioPluginPresent,
    selectIsLocalScreenShared,
    selectRoom,
    useAVToggle,
    useHMSActions,
    useHMSStore,
} from "@100mslive/react-sdk";
import { HMSKrispPlugin } from "@100mslive/hms-noise-cancellation";
import { useDispatch, useSelector } from "react-redux";
import { endMentorshipSessionAction } from "../../redux/reducers/mentorship";
import moment from "moment";
import { useCookies } from "react-cookie";
import CustomAlertDialog from "../../teacherroutes/schedule/CustomAlertDialog";

const plugin = new HMSKrispPlugin();

function Footer({ hostAuthToken, selectedEvent }) {
    const dispatch = useDispatch();
    const currentDateTime = moment();
    // const actions = useHMSActions();
    const hmsActions = useHMSActions();
    const [cookies, setCookie, removeCookie] = useCookies();
    const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } = useAVToggle();

    const [showDialog, setShowDialog] = useState(false);

    // const [isPluginActive, setIsPluginActive] = useState(false);
    // const amIScreenSharing = useHMSStore(selectIsLocalScreenShared);
    // const room = useHMSStore(selectRoom);
    // const isAudioPluginAdded = useHMSStore(
    //     selectIsLocalAudioPluginPresent(plugin.getName())
    // );

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (selectedEvent?.endDate) {
                const currentDateTime = moment();
                const eventEndDate = moment(selectedEvent.endDate);
                if (eventEndDate.isBefore(currentDateTime)) {
                    hmsActions.endRoom(true, "End Room");
                    clearInterval(intervalId);
                } else {
                    console.log("Hello");
                }
            }
        }, 50000);


        return () => clearInterval(intervalId);
    }, [selectedEvent]);

    useEffect(() => {

        return () => {
            handleLeaveRoom();
        }
    }, [])


    const handleLeaveRoom = async () => {
        const formattedDateTime = currentDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        toggleAudio();
        toggleVideo();
        hmsActions.leave();
        dispatch(endMentorshipSessionAction(
            {
                role: "teacher",
                id: cookies?.sessionId,
                date: formattedDateTime,
                isCompleted: false
            }
        ));
    };

    const handleExitRoom = () => {
        const formattedDateTime = currentDateTime.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        dispatch(endMentorshipSessionAction(
            {
                role: "teacher",
                id: cookies?.sessionId,
                date: formattedDateTime,
                isCompleted: true,
            }
        ));
        hmsActions.endRoom(true, "End Room");
    };


    return (
        <div className="control-bar">
            <button
                className={`btn-control ${isLocalAudioEnabled ? "" : "highlight"}`}
                onClick={toggleAudio}
            >
                {isLocalAudioEnabled ? <MicOnIcon /> : <MicOffIcon />}
            </button>
            <button
                className={`btn-control ${isLocalVideoEnabled ? "" : "highlight"}`}
                onClick={toggleVideo}
            >
                {isLocalVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
            </button>
            {/* <button
                title="Screenshare"
                className={`btn-control ${amIScreenSharing ? "" : "highlight"}`}
                onClick={() => actions.setScreenShareEnabled(!amIScreenSharing)}
            >
                <ShareScreenIcon />
            </button> */}
            <button
                id="leave-btn"
                className="btn btn-danger d-flex align-items-center"
                onClick={() => handleLeaveRoom()}
            >
                Leave Room
            </button>
            <button
                id="leave-btn"
                className="btn btn-danger d-flex align-items-center"
                onClick={() => setShowDialog(true)}
            >
                <ExitIcon style={{ marginLeft: "0.25rem" }} className="me-2" /> End Session
            </button>
            {/* {room.isNoiseCancellationEnabled ? (
                <button
                    title="Noise cancellation"
                    className={`btn-control ${isPluginActive ? "" : "highlight"}`}
                    onClick={async () => {
                        if (isAudioPluginAdded) {
                            plugin.toggle();
                            setIsPluginActive((prev) => !prev);
                        } else {
                            await actions.addPluginToAudioTrack(plugin);
                            setIsPluginActive(true);
                        }
                    }}
                >
                    <AudioLevelIcon />
                </button>
            ) : null} */}
            <CustomAlertDialog
                title={"End Session"}
                isOpen={showDialog}
                onClose={() => setShowDialog(false)}
                onSubmit={handleExitRoom}
            >
                Are you sure? You want to End Session.
            </CustomAlertDialog>
        </div>
    );
}

export default Footer;