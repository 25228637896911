import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";

import { useIsAuthenticated } from "../App/Context";
import { Box } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import { CommonHeader } from "../Components/CommonHeader";
import { LoginRoute } from "./Login";
import { TeacherDoubtScreen } from "../teacherroutes/doubt";
import Desk from '../supportRoutes/desk'
import { SingleDoubt } from "../teacherroutes/SingleDoubt";
import { useSelector } from "react-redux";
import { CallBacks } from "../teacherroutes/callback";
import { SingleCommonDoubt } from "../teacherroutes/SingleCommonDoubt";
import { TeacherChatScreen } from "../teacherroutes/chat";
import { ThreeD } from "../teacherroutes/threeD";
import TeacherScheduleScreen from "../teacherroutes/schedule";
import MentorshipScreen from "../teacherroutes/mentorship";

export const AppRouter = (props) => {
  const isAuthemticated = useIsAuthenticated();
  const history = useHistory();
  const user = useSelector(s => s.user.user);
  const location = useLocation()
  useEffect(() => {
    // isAuthemticated && history.push("/doubts");
  }, [isAuthemticated, history]);
  return (
    <Box h="100vh" style={{ flexDirection: "column", display: "flex" }}>
      <CommonHeader />
      {isAuthemticated ? (
        <Flex w="100%" flex={1}>
          {/* <Box {...webView} bg="gray.50" flex={1}>
            <SideBarContent />
          </Box> */}
          <Box flex={5}>
            {
              user?.staff?.staffRole === 'SUPPORT_EXECUTIVE' ?
                <SupportRoutes />
                :
                <TeacherRoutes />
            }
          </Box>
          {/* <FooterComponent /> */}
        </Flex>
      ) : (
        <Flex
          w="100vw"
          h="80vh"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* <Text fontSize="lg">You are not logged in</Text>
          <Text>Please signin to continue</Text> */}

          <LoginRoute />
        </Flex>
      )}
    </Box>
  );
};

const TeacherRoutes = (props) => {
  return (
    <Switch>
      {/* <ProtectedRoute path="/dashboard" component={AppRoutes} />       */}
      {/* <Route path={ROUTES.PUBLIC_CART}>
      <Cart />
    </Route> */}
      <ProtectedRoute exact path="/">
        <Redirect to="/schedule" />
      </ProtectedRoute>
      <ProtectedRoute path="/doubts/:page?/:staffId?">
        <TeacherDoubtScreen />
      </ProtectedRoute>
      <ProtectedRoute path="/chat">
        <TeacherChatScreen />
      </ProtectedRoute>
      <ProtectedRoute path="/d/:id">
        <SingleDoubt />
      </ProtectedRoute>
      <ProtectedRoute path="/common-doubt/:id">
        <SingleCommonDoubt />
      </ProtectedRoute>
      <ProtectedRoute path="/callbacks/:page?/:staffId?">
        {/* <ProtectedRoute path="/callback/:staffId?"> */}
        <CallBacks />
      </ProtectedRoute>
      <ProtectedRoute path="/threeD">
        <ThreeD />
      </ProtectedRoute>
      <ProtectedRoute path="/schedule">
        <TeacherScheduleScreen />
      </ProtectedRoute>
      <ProtectedRoute path="/mentorship">
        <MentorshipScreen />
      </ProtectedRoute>
    </Switch>
  );
};

const SupportRoutes = (props) => {
  return (
    <Switch>
      {/* <ProtectedRoute path="/dashboard" component={AppRoutes} />       */}
      {/* <Route path={ROUTES.PUBLIC_CART}>
      <Cart />
    </Route> */}
      <ProtectedRoute exact path="/">
        <Redirect to="/desk" />
      </ProtectedRoute>
      <ProtectedRoute path="/desk">
        <Desk />
      </ProtectedRoute>
    </Switch>
  );
};

export const ProtectedRoute = (props) => {
  const auth = useIsAuthenticated();
  return auth ? (
    <Route {...props} /> // same as <Route path="/dashboard" component={AppRoutes} />
  ) : (
    <Redirect to={"/"} />
  );
};

export default AppRouter;
